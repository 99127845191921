import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './StatTable.css';
import { LeaderTable, LeaderRow } from '../../../../components/LeaderTable';

class StatTable extends Component {
  static propTypes = {
    myStats: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  UNSAFE_componentWillMount() {
    if (Array.isArray(this.props.myStats)) {
      for (let i = 0; i < this.props.myStats.length; i++) {
        this.props.myStats[i].key = `${i}`;
      }
      this.myStatsDivs = this.props.myStats.map(props => (
        <LeaderRow {...props} />
      ));
    } else {
      this.myStatsDivs = <div />;
    }
  }

  render() {
    return <LeaderTable>{this.myStatsDivs}</LeaderTable>;
  }
}

const mapStateToProps = state => ({
  myStats: state.myStats.myStats,
});

export default connect(mapStateToProps)(StatTable);
