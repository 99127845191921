import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LeaderRow.css';

class LeaderRow extends Component {
  static propTypes = {
    children: PropTypes.node,
    linkTo: PropTypes.string,
    rowKey: PropTypes.node.isRequired,
    rowVal: PropTypes.node.isRequired,
  };

  UNSAFE_componentWillMount() {
    const linkExists =
      typeof this.props.linkTo !== 'undefined' && this.props.linkTo !== null;
    const popoverExists =
      typeof this.props.children !== 'undefined' &&
      this.props.children !== null;

    function createChildrenHtml(children) {
      return {
        __html: children,
      };
    }

    if (popoverExists) {
      this.popoverElement = (
        <div
          id={'popover'}
          dangerouslySetInnerHTML={createChildrenHtml(this.props.children)}
        />
      );
    } else {
      this.popoverElement = <div />;
    }

    if (linkExists) {
      this.outElement = (
        <a className={'stat'} href={this.props.linkTo} target={'_blank'}>
          <li id={'hover'}>
            <span>{this.props.rowKey}</span>
            <span>
              {this.props.rowVal}
              <i className={'fa fa-external-link'} aria-hidden />
            </span>
            {this.popoverElement}
          </li>
        </a>
      );
    } else {
      this.outElement = (
        <li id={'hover'}>
          <span>{this.props.rowKey}</span>
          <span>{this.props.rowVal}</span>
          {this.popoverElement}
        </li>
      );
    }
  }

  render() {
    return this.outElement;
  }
}

export default LeaderRow;
