import React from 'react';
import PropTypes from 'prop-types';
import './CrypticCrosswordLink.css';

function CrypticCrosswordLink({ title, url, publication, issue }) {
  let displayText = `${issue} - ${publication}`;
  if (displayText.length > 33) {
    displayText = displayText.slice(0, 30);
    displayText = `${displayText}...`;
  }
  return (
    <a className={'cryptic-crossword-root'} href={url} title={title}>
      <i className={'fa fa-file-pdf-o'} aria-hidden></i>
      {displayText}
      <br />
      <em className={'title'}>{title}</em>
    </a>
  );
}

CrypticCrosswordLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  issue: PropTypes.string.isRequired,
  publication: PropTypes.string.isRequired,
};

export default CrypticCrosswordLink;
