import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import Home from '../../routes/home/Home';

import './App.css';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route component={Home} />
      </Switch>
    );
  }
}

export default App;
