import React from 'react';
import PropTypes from 'prop-types';
import s from './ChessGameLink.css';

function ChessGameLink({ date, title, winnerWasMe, result, pdfAnnotationUrl }) {
  return (
   <div className={'chessgame-root'}>
      <a href={pdfAnnotationUrl}>
        <div>
          <i className={'fa fa-file-pdf-o'} aria-hidden></i>
          {`${date}`}
        </div>
        <em className={'gameResult'}>
          {`${title}`}
          <span className={winnerWasMe ? 'win' : 'loss'}>
           {`(${result})`}
          </span>
        </em>
      </a>
    </div>
  );
}

ChessGameLink.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  winnerWasMe: PropTypes.bool.isRequired,
  result: PropTypes.string.isRequired,
  pdfAnnotationUrl: PropTypes.string,
};

export default ChessGameLink;
