import React from 'react';
import PropTypes from 'prop-types';
import './LatestTweet.css';

let key = 0;
function linkifyText(target, text) {
  const myKey = ++key;
  return (
    <a href={target} className={'tweetLink'} target={"_blank"} key={`myTweet${myKey}`}>
      {`${text} `}
    </a>
  );
}

function splitTextByEntityRanges(text, entities) {
  const entityRanges = entities.user_mentions.concat(entities.urls);
  entityRanges.sort((a, b) => a.indices[0] - b.indices[0]);
  const substrings = [];
  let i = 0;
  for (const entityRange of entityRanges) {
    if ((i + 1) !== entityRange.indices[0]) {
      substrings.push({
        text: text.substring(i, entityRange.indices[0] - 1),
        entity: {},
      });
    }
    substrings.push({
      text: text.substring(
        entityRange.indices[0],
        entityRange.indices[1]
      ),
      entity: entityRange,
    });
    i = entityRange.indices[1];
  }
  if ((i + 1) < (text.length - 1)) {
    substrings.push({
      text: text.substring(i, text.length - 1),
      entity: {},
    });
  }
  return substrings;
}

function setLinkTarget(tweetId) {
  return function setLinkTargetWithTweetId(substring) {
    let result = { text: substring.text };
    if (substring.entity.hasOwnProperty('screen_name')) {
      result.target = `https://twitter.com/${substring.entity.screen_name}`;
    } else if (substring.entity.hasOwnProperty('url')) {
      result.target = substring.entity.url;
    } else {
      result.target = `https://twitter.com/brandonarnold/status/${tweetId}`;
    }
    return result;
  };
}

function LinkifiedTweet({ id_str, text, entities }) {
  let substrings = splitTextByEntityRanges(text, entities);
  substrings = substrings.map(setLinkTarget(id_str));
  return (
    <div className={'twitterText'}>
      {substrings.map(a => linkifyText(a.target, a.text))}
    </div>
  );
}

LinkifiedTweet.propTypes = {
  id_str: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  entities: PropTypes.object.isRequired,
};

export { linkifyText, splitTextByEntityRanges, setLinkTarget, LinkifiedTweet };
export default LinkifiedTweet;
