import React from 'react';
import PropTypes from 'prop-types';
import './LeaderTable.css';

function LeaderTable({ children }) {
  return (
    <ul className={'statLeaders'}>
      {children}
    </ul>
  );
}

LeaderTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LeaderTable;
