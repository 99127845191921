import React from 'react';

// splitDiv -- splits a list of react components into Divs
//
// Inputs
//  - innerList: list of react components to split into Divs
//  - splitCount: number of components per Divs
//  - name: Unique name to put in Div keys
//
function splitDiv(innerList, splitCount, name) {
  if (typeof(innerList) === 'undefined' ||
     typeof(splitCount) === 'undefined' ||
     typeof(name) === 'undefined') {
    throw new TypeError('Argument error: splitDiv not given 3 arguments');
  }

  const returned = [];
  const limit = Math.min(5, (innerList.length / splitCount));
  for (let i = 0; i < limit; i++) {
    let curElements = innerList.slice(0, splitCount);
    returned.push(
      <div key={`${name}${i}`}>
        {curElements}
      </div>
    );
    innerList.splice(0, splitCount);
  }
  if (innerList.length > 0 && innerList.length < splitCount) {
    returned.push(
      <div key={`${name}Final`}>
        {innerList}
      </div>
    );
  }
  return returned;
}

export default splitDiv;
