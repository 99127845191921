import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import ChessGameLink from './ChessGameLink';
import CrypticCrosswordLink from './CrypticCrosswordLink';
import YouTubeLink from './YouTubeLink';
import splitDiv from './SplitDiv';
import StatTable from './StatTable';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './News.css';

class News extends Component {
  static propTypes = {
    news: PropTypes.shape({
      crypticCrossword: PropTypes.arrayOf(PropTypes.object).isRequired,
      chessGame: PropTypes.arrayOf(PropTypes.object).isRequired,
      tetrisGame: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  };

  UNSAFE_componentWillMount() {
    // Split news entries into sets of 3 or 4 for the carousels
    const ccEntries = this.props.news.crypticCrossword;
    this.ccLinks = _(ccEntries)
      .orderBy(['order'], ['desc'])
      .map(ccProps => <CrypticCrosswordLink key={ccProps.issue} {...ccProps} />)
      .value();
    this.ccSections = splitDiv(this.ccLinks, 4, 'ccSection');

    const chessGames = this.props.news.chessGame;
    this.chessGameLinks = _(chessGames)
      .orderBy(['order'], ['desc'])
      .map(cgProps => (
        <ChessGameLink key={`${cgProps.date}: ${cgProps.title}`} {...cgProps} />
      ))
      .value();
    this.chessGameSections = splitDiv(
      this.chessGameLinks,
      4,
      'chessGameSection'
    );

    const ytGames = this.props.news.tetrisGame;
    this.ytGameLinks = _(ytGames)
      .orderBy(['date'], ['desc'])
      .map(ytProps => (
        <YouTubeLink key={`${ytProps.date}: ${ytProps.text}`} {...ytProps} />
      ))
      .value();
    this.ytSections = splitDiv(this.ytGameLinks, 3, 'ytSections');

    // settings for react-slick carousel control -- see react-slick README
    this.slSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'aSliderItem',
    };
  }

  render() {
    return (
      <div>
        <div className={'amusements'}>
          <div className={'newsSection'}>
            <h4>Stats</h4>
            <StatTable />
          </div>
            <div className={'newsSection topNews'}>
            <h4>Tetris</h4>
            <Slider {...this.slSettings}>{this.ytSections}</Slider>
          </div>
          <div className={'newsSection bottomNews'}>
            <h4>Cryptic Crossword</h4>
            <Slider {...this.slSettings}>{this.ccSections}</Slider>
          </div>
          <div className={'newsSection bottomNews'}>
            <h4>Chess</h4>
            <Slider {...this.slSettings}>{this.chessGameSections}</Slider>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  news: state.news,
});

export default connect(mapStateToProps)(News);
