import fetch from '../../../core/fetch';

export const SET_LATEST_TWEET = 'SET_LATEST_TWEET';

export const updateLatestTweet = () =>
  (dispatch) =>
    fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
{
  myTweets {
    text,
    id_str,
    entities {
      user_mentions { id, id_str, indices, screen_name },
      urls { expanded_url, indices, url }
    }
  }
}
        `,
      }),
      credentials: 'include',
    }).then(response => response.json())
      .then(json => dispatch({ type: SET_LATEST_TWEET, myTweets: json.data }));

const ACTION_HANDLERS = {
  [SET_LATEST_TWEET]: (state, { myTweets }) =>
    Object.assign({}, state, myTweets),
};

export default function nameplate(state = {}, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
