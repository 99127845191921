import React from 'react';
import './FollowTwitter.css';

function FollowTwitter() {
  return (
    <div className={'twitterFollowEmbedded'}>
      <a
        href={'https://twitter.com/brandonarnold'}
        className={'twitter-follow-button'}
      >
        {'Follow @brandonarnold'}
      </a>
    </div>
  );
}

export default FollowTwitter;
