import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App/App';
import configureStore from './store/configureStore';

const store = configureStore(window.__PRELOADED_STATE__, {});

// Fix: Expected server HTML to contain a matching <div> in <div>
const renderMethod = module.hot ? render : hydrate;

renderMethod(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
