import React from 'react';
import PropTypes from 'prop-types';

import Nameplate from './Nameplate';
import News from './News';
import Footer from '../../components/Footer/';

import './Home.css';

function Home(props, context) {

  return (
    <div className={'home-root'}>
      <div className="masthead">
        <div className="nameplate">
          <Nameplate />
        </div>
        <div className="news">
          <News />
        </div>
      </div>
      <div className="footer">
          <Footer />
      </div>
    </div>
  );
}

export default Home;
