import fetch from '../../../core/fetch';

function updateNews(graphQlQuery, newsActionType) {
  return (dispatch) =>
    fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: graphQlQuery,
      }),
      credentials: 'include',
    }).then(response => response.json())
      .then(json => {
        const newsAction = { type: newsActionType, newsEntries: json.data };
        dispatch(newsAction);
      });
}

export const SET_BLOGENTRIES = 'SET_BLOGENTRIES';
export const SET_CRYPTIC_CROSSWORD_ENTRIES =
  'SET_CRYPTIC_CROSSWORD_ENTRIES';
export const SET_CHESS_GAMES = 'SET_CHESS_GAMES';
export const SET_TETRIS_GAMES = 'SET_TETRIS_GAMES';

const blogEntryQuery = '{blogEntry { title, description } }';
const crypticCrosswordQuery =
  '{crypticCrossword { order, title, url, publication, issue, completionDate } }';
const chessGameQuery = '{chessGame { order, date, title, result, winnerWasMe, pdfAnnotationUrl } }';
const tetrisGameQuery = '{ tetrisGame { date, text, platform, points, url } }';

export const updateBlogEntries = () => updateNews(blogEntryQuery, SET_BLOGENTRIES);
export const updateCrypticCrosswordEntries = () =>
  updateNews(crypticCrosswordQuery, SET_CRYPTIC_CROSSWORD_ENTRIES);
export const updateChessGames = () => updateNews(chessGameQuery, SET_CHESS_GAMES);
export const updateTetrisGames = () => updateNews(tetrisGameQuery, SET_TETRIS_GAMES);

const newsActionHandler = (state, { newsEntries }) => Object.assign({}, state, newsEntries);

const ACTION_HANDLERS = {
  [SET_BLOGENTRIES]: newsActionHandler,
  [SET_CRYPTIC_CROSSWORD_ENTRIES]: newsActionHandler,
  [SET_CHESS_GAMES]: newsActionHandler,
  [SET_TETRIS_GAMES]: newsActionHandler,
};

export default function news(state = {}, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
