import React from 'react';
import PropTypes from 'prop-types';
import './YouTubeLink.css';

function YouTubeLink({ date, url, text }) {
  return (
    <div className={'youtube-root'}>
      <a href={url} target={'_blank'}>
        <div>
          <i className={'fa fa-youtube-play'} aria-hidden></i>
          {`${date}`}
        </div>
        <em className={'text'}>
          {`${text}`}
        </em>
      </a>
    </div>
  );
}

YouTubeLink.propTypes = {
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default YouTubeLink;
