import fetch from '../../../../core/fetch';

export const SET_MY_STATS = 'SET_MY_STATS';

export function updateMyStats() {
    return (dispatch) =>
    fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: '{ myStats { rowKey, rowVal, linkTo, children } }',
        }),
        credentials: 'include',
    }).then(response => response.json())
        .then(json => {
            const statsAction = { type: SET_MY_STATS, myStats: json.data };
            dispatch(statsAction);
        });
}

const ACTION_HANDLERS = {
    [SET_MY_STATS]: (state, { myStats }) =>
    Object.assign({}, state, myStats),
};

export default function news(state = {}, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
