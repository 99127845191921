import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Nameplate.css';
import FollowTwitter from './FollowTwitter/FollowTwitter';
import LatestTweet from './LatestTweet/LatestTweet';

class Nameplate extends Component {
  static propTypes = {
    nameplate: PropTypes.shape({
      myTweets: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  constructor() {
    super();
    this.vidUrlWebm =
      'https://static.brandonarnold.com/video/action200x200.webm';
    this.vidUrlMp4 = 'https://static.brandonarnold.com/video/action200x200.mp4';
    this.vidUrlGif = 'https://static.brandonarnold.com/video/action200x200.gif';
    /*
       Cut together using Kdenlive, then crop with Handbrake (that must
       save in m4v), and scale down again in Kdenlive and output to the
       webm/mp4 formats.
    */
    this.mitPgpUrl =
      'https://pgp.mit.edu/pks/lookup?op=get&search=0x1AF42F52F60E31AC';
  }


  UNSAFE_componentWillMount() {
    const myTweets = this.props.nameplate.myTweets;
    if (myTweets) {
      this.twitterSection = <LatestTweet {...myTweets[0]} />;
    } else {
      this.twitterSection = <FollowTwitter />;
    }
  }  
    
  render() {
    return (
      <div className="nameplate-root">
        <video autoPlay loop poster={this.vidUrlGif} className="actionVid">
          <source src={this.vidUrlWebm} type={'video/webm'} />
          <source src={this.vidUrlMp4} type={'video/mp4'} />
          <source src={this.vidUrlGif} type={'image/gif'} />
          <img
            className="actionVidAlt"
            src={this.vidUrlGif}
            alt={'presentation'}
            role={'presentation'}
          />
        </video>
        <h1>Brandon Arnold</h1>
        <div className="headingInfo">
          <ul className="headingInfo">
            <li className="title">Software Engineer</li>
            <li>
              <span className="emailHeading">br</span>
              <span className="atSymbol">@</span>
              <span className="emailHeading">ndonarnold.email</span>
            </li>
            <li>
              <span
                className="pgpKey"
                title={"Brandon Arnold's PGP Key Fingerprint"}
              >
                51DA 9774 EC11 39B3 1184 390F 1AF4 2F52 F60E 31AC&nbsp;
              </span>
            </li>
            <li className="pgpKeyLink">
              <a
                className="pgpKeyLink"
                href={this.mitPgpUrl}
                target={'_blank'}
              >
                <i className={'fa fa-lock fa-fw black-icon'} aria-hidden />
                Brandon&apos;s PGP Key&nbsp;
                <i className={'fa fa-external-link'} aria-hidden />
              </a>
            </li>
          </ul>
        </div>
        <div className={'twitterSection'}>{this.twitterSection}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  nameplate: state.nameplate,
});

export default connect(mapStateToProps)(Nameplate);
