import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LatestTweet.css';
import LinkifiedTweet from './LinkifiedTweet';

class LatestTweet extends Component {
  static propTypes = {
    id_str: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    entities: PropTypes.object.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.linkifiedTweet = <LinkifiedTweet {...this.props} />;
  }

  render() {
    return (
      <div className={'latestTweet'}>
        {this.linkifiedTweet}
        <div className={'twitterFollowEmbedded'}>
          <a
            href={'https://twitter.com/brandonarnold'}
            className={'twitter-follow-button'}
          >
            {'Follow @brandonarnold'}
          </a>
        </div>
      </div>
    );
  }
}

export default LatestTweet;
