import { combineReducers } from 'redux';
import runtime from './runtime';
import news from '../routes/home/News/News.redux.js';
import nameplate from '../routes/home/Nameplate/Nameplate.redux.js';
import myStats from '../routes/home/News/StatTable/StatTable.redux.js';

export default combineReducers({
  runtime,
  news,
  nameplate,
  myStats,
});
