import React from 'react';

function Footer() {
  return (
    <div>
      &copy; 2015-{new Date().getFullYear()}, Brandon Nicholas Arnold
    </div>
  );
}

export default Footer;
